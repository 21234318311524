<template>
  <div>
    <div class="box">
      <el-card class="left">
        <ul style="overflow:auto">
          <li style="color:#37a58c;font-size:16px;">分类列表</li>
          <li
            v-for="(item, index) in materialList"
            :key="item.id"
            @click="toggleClass(index, item.id)"
            :class="{ selected: cur == index }"
          >
            {{ item.name }}
            <div class="ck">
              <i class="el-icon-edit" @click="editTab(item)"></i>
              <i class="el-icon-delete" @click="removeTab(item.id)"></i>
            </div>
          </li>
        </ul>
        <el-button
          type="primary"
          size="medium"
          class="add"
          @click="addDialog = true"
        >
          添加分类
        </el-button>
      </el-card>
      <div class="right">
        <el-card class="contents">
          <div class="search">
            <div class="search-left">
              <el-form :inline="true" label-width="80px" ref="search">
                <!-- <el-form-item label="单词：" label-width="100px">
                  <el-input
                    placeholder="请输入单词"
                    v-model="queryparms.file_name"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="toQuery">查询</el-button>
                </el-form-item> -->
              </el-form>
            </div>
            <div class="search-right">
              <el-button type="primary" @click="addLetter">添加单词</el-button>
            </div>
          </div>
          <el-form ref="form" :inline="true">
            <el-table
              :data="cate_word_list"
              border
              class="table"
              v-loading="loading"
            >
              <el-table-column
                prop="id"
                label="编号"
                width="100"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="word"
                label="法语单词"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="note"
                label="中文"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="examples[0].sentence"
                label="词性"
                align="center"
              ></el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-button
                    type="primary"
                    icon="el-icon-edit"
                    circle
                    @click="handleItemEdit(scope.$index, scope.row)"
                    size="small"
                  ></el-button>
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="small"
                    circle
                    @click="handleItemDelete(scope.$index, scope.row)"
                  ></el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form>

          <!-- 分页区域 -->
          <!-- <div class="pagination">
            <span></span>
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryparms.page"
              :page-sizes="[12, 24, 36, 48]"
              :page-size="queryparms.limit"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              algin:="center"
              ></el-pagination>
          </div> -->
        </el-card>
      </div>
    </div>

    <!-- 添加分类 -->
    <el-dialog title="添加分类" :visible.sync="addDialog" width="30%">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="分类名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label-width="40%">
          <el-button type="primary" @click="addTab">
            立即添加
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 修改分类 -->
    <el-dialog title="修改分类" :visible.sync="editDialog" width="30%">
      <el-form
        :model="editForm"
        :rules="rules"
        ref="editForm"
        label-width="100px"
      >
        <el-form-item label="分类名称" prop="title">
          <el-input v-model="editForm.name"></el-input>
        </el-form-item>
        <el-form-item label-width="40%">
          <el-button type="primary" @click="editTabs">
            立即修改
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 添加/编辑单词弹窗 -->
    <el-dialog
      :title="addFileDialogTitle"
      :visible.sync="addFileDialog"
      width="30%"
      @closed="closeBox"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <template>
        <el-form :model="addLetterForm" ref="ruleForm" label-width="100px">
          <el-form-item label="单词" prop="word" label-width="50px">
            <el-input v-model="addLetterForm.word"></el-input>
          </el-form-item>
          <el-form-item label="中文" prop="note" label-width="50px">
            <el-input v-model="addLetterForm.note"></el-input>
          </el-form-item>
          <el-form-item label="词性" prop="examples" label-width="50px">
            <el-input v-model="addLetterForm.examples[0].sentence"></el-input>
          </el-form-item>
          <el-form-item el-form-item label="音频" label-width="50px">
            <div v-if="audio_url && fileAudioName == ''">
              <audio
                :src="audio_url"
                style="cursor: pointer;outline: none;"
                controls="controls"
                ref="audioPlay"
              ></audio>
            </div>
            <el-button type="primary" @click="selectAudio">{{
              isAdd == "add" ? "上传音频" : "修改音频"
            }}</el-button>
            <span class="file-name">{{ fileAudioName }}</span>
            <el-progress
              :percentage="audioProgress"
              v-if="audioProgress"
            ></el-progress>
            <input
              type="file"
              name="audio"
              id=""
              hidden
              ref="audio"
              @change="selectAudioFiles"
              accept="audio/*"
            />
          </el-form-item>
          <el-form-item label-width="40%">
            <el-button type="primary" @click="addNow" v-if="isAdd == 'add'"
              >立即添加</el-button
            >
            <el-button
              type="primary"
              v-else-if="isAdd == 'edit'"
              @click="editNow"
              >立即修改</el-button
            >
          </el-form-item>
        </el-form>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  getMaterial,
  addCategory,
  removeCategory,
  editCategory,
  getFile,
  editFile,
  delFile,
  addFile,
  FileRecycleDel,
  FileRestore,
  FileCosToken,
  addExamTitle,
  titleListByThemeId,
  updateTitleById,
  delTitleById
} from "@/api/material";
import {
  letterCateList,
  letterCateDel,
  letterCateAdd,
  letterCateUpdate,
  letterCateWordList,
  delLetter
} from "@/api/French/french_wordResources";
import {
  addLetter,
  updateLetter,
  getLetterList,
  getLetterDetail,
  getReadCosToken
} from "@/api/French/french_alphabet";
var COS = require("cos-js-sdk-v5");
export default {
  data() {
    return {
      materialList: [],
      addDialog: false,
      editDialog: false,
      ruleForm: {
        name: "",
        type: 3
      },
      editForm: {
        name: "",
        id: null
      },
      rules: {
        name: [
          {
            required: true,
            message: "分类名称不能为空",
            trigger: "blur"
          },
          { min: 1, max: 8, message: "长度在 1 到 8 个字符", trigger: "blur" }
        ],
        // title: [
        //   {
        //     required: true,
        //     message: "修改的分类名称不能为空",
        //     trigger: "blur",
        //   },
        //   {
        //     min: 1,
        //     max: 50,
        //     message: "长度在 1 到 50 个字符",
        //     trigger: "blur",
        //   },
        // ],
        orderby: [
          {
            required: true,
            message: "素材排序不能为空",
            trigger: "blur"
          }
        ],
        file_name: [
          {
            required: true,
            message: "素材名称不能为空",
            trigger: "blur"
          },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur"
          }
        ],
        cate_id: [
          {
            required: true,
            message: "请选择分类",
            trigger: "blur"
          }
        ]
      },

      queryparms: {
        page: 1,
        limit: 12,
        file_name: null,
        file_type: null,
        cate_id: null,
        closed: null,
        mold: "french"
      },
      cur: -1,
      FileList: [],
      total: 0,
      addFileForm: {
        cate_id: null,
        file_name: "",
        key: "",
        file_type: "",
        mold: "french",
        is_interaction: "0"
      },
      addFileDialog: false,
      fileList: [{ name: "food.jpeg" }],
      addBatchFrom: {
        cate_id: null,
        file_name: "",
        file_type: "",
        key: "",
        image: null,
        mold: "french"
      },
      loading: false,
      search_type: "",
      addLetterForm: {
        word: "",
        voice_file: "",
        note: "",
        examples: [
          {
            sentence: ""
          }
        ],
        mold_type: 3,
        book: 1,
        cate_id: null
      },
      audio_url: "",
      fileAudioName: "",
      audioProgress: 0,
      isAdd: "add",
      cate_word_list: [],
      addFileDialogTitle: ""
    };
  },
  watch: {
    fileAudioName: function(news, old) {
      if (news != "" && this.addLetterForm.word == "") {
        this.addLetterForm.word = news.split(".")[0];
      }
    }
  },
  methods: {
    // 点击编辑图标
    handleItemEdit(index, row) {
      this.isAdd = "edit";
      this.addFileDialogTitle = "编辑单词";
      this.addFileDialog = true;
      this.addLetterForm.word = row.word;
      this.addLetterForm.note = row.note;
      this.addLetterForm.voice_file = row.voice_file
        .split(".com/")[1]
        .split("?sign")[0];
      this.addLetterForm.examples = JSON.parse(row.example);
      this.audio_url = row.voice_file;
      this.addLetterForm.id = row.id;
    },
    // 点击删除图标
    async handleItemDelete(index, row) {
      const result = await this.$confirm("是否删除该单词?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).catch(err => err);
      if (result !== "confirm") return this.$message.info("已经取消删除");
      delLetter({ id: row.id }).then(res => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("已删除该单词");
        this.getLetterCateWordList(this.addLetterForm.cate_id);
      });
    },
    //立即编辑
    editNow() {
      if (this.addLetterForm.word == "") {
        this.$message.error("单词不能为空！");
        return;
      } else if (this.addLetterForm.note == "") {
        this.$message.error("中文为空！");
        return;
      } else if (this.addLetterForm.examples[0].sentence == "") {
        this.$message.error("词性不能为空！");
        return;
      } else if (this.addLetterForm.voice_file == "") {
        this.$message.error("音频不能为空！");
        return;
      }
      updateLetter(this.addLetterForm).then(res => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("修改成功!");
        this.addFileDialog = false;
        this.getLetterCateWordList(this.addLetterForm.cate_id);
      });
    },
    // 点击添加单词按钮
    addLetter() {
      if (this.addFileForm.cate_id === null) {
        this.$message.error("请选择分类");
        return;
      }
      this.addFileDialogTitle = "添加单词";
      this.isAdd = "add";
      this.addFileDialog = true;
    },
    // 添加/编辑单词弹窗关闭事件
    closeBox() {
      this.addLetterForm.word = "";
      this.addLetterForm.voice_file = "";
      this.addLetterForm.note = "";
      this.addLetterForm.examples = [{ sentence: "" }];
      this.audio_url = "";
      this.audioProgress = 0;
      this.fileAudioName = "";
    },

    // 点击选择单词音频
    selectAudio() {
      console.log(this.$refs.audio);
      this.$refs.audio.click();
    },
    // 选中音频
    selectAudioFiles(e) {
      let file = this.$refs.audio.files[0];
      this.fileAudioName = file.name;
      const isSize = file.size / 1024 / 1024 < 30;
      if (!isSize) {
        this.$message.error("上传音频不能超过 30MB!");
        return;
      }

      let that = this;
      getReadCosToken({ suffix: file.name }).then(res => {
        var cos = new COS({
          getAuthorization: function(options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId
            });
          }
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function(progressData) {
              that.audioProgress = progressData.percent * 100;
            }
          },
          function(err, data) {
            if (data) {
              that.addLetterForm.voice_file = res.data.key;
            }
            e.target.value = "";
          }
        );
      });
    },
    //立即添加
    addNow() {
      if (this.addLetterForm.word == "") {
        this.$message.error("单词不能为空！");
        return;
      } else if (this.addLetterForm.note == "") {
        this.$message.error("中文为空！");
        return;
      } else if (this.addLetterForm.examples[0].sentence == "") {
        this.$message.error("词性不能为空！");
        return;
      } else if (this.addLetterForm.voice_file == "") {
        this.$message.error("音频不能为空！");
        return;
      }
      addLetter(this.addLetterForm).then(res => {
        console.log(res);
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("添加成功!");
        this.addFileDialog = false;
        this.getLetterCateWordList(this.addLetterForm.cate_id);
      });
    },
    // 获取分类单词列表
    getLetterCateWordList(cate_id) {
      letterCateWordList({ cate_id: cate_id }).then(res => {
        if (Array.isArray(res.data)) {
          res.data.forEach(item => {
            item.examples = JSON.parse(item.example);
          });
          this.cate_word_list = res.data;
          // this.total = res.data.total;
        } else {
          this.cate_word_list = [];
        }
      });
    },
    // 获取侧边分类
    getMaterialList() {
      letterCateList({ type: 3 }).then(res => {
        this.materialList = res.data;
        this.getLetterCateWordList(this.materialList[0].id);
        this.addLetterForm.cate_id = this.materialList[0].id;
      });
    },
    // 删除侧边分类
    async removeTab(id) {
      const result = await this.$confirm("是否要删除该分类?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).catch(err => err);
      if (result !== "confirm") return this.$message.info("已经取消删除");
      letterCateDel({ id: id }).then(res => {
        this.getMaterialList();
        this.$message.success("删除分类成功");
      });
    },
    // 添加侧边分类
    addTab() {
      if (this.ruleForm.name == "") {
        this.$message.error("分类名不能为空");
        return;
      }
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          letterCateAdd(this.ruleForm).then(res => {
            this.$message.success("添加分类成功");
            this.getMaterialList();
            this.ruleForm.name = "";
            this.addDialog = false;
          });
        } else {
          return false;
        }
      });
    },
    // 点击修改侧边分类图标
    editTab(item) {
      this.editForm.id = item.id;
      this.editForm.name = item.name;
      this.editDialog = true;
    },
    // 点击立即修改按钮,提交表单,修改分类名称
    editTabs() {
      if (this.editForm.name == "") {
        this.$message.error("分类名称不能为空");
        return;
      }
      letterCateUpdate(this.editForm).then(res => {
        if (res.code !== 1) return this.$message.error("修改分类失败");
        this.$message.success("修改分类成功");
        this.getMaterialList();
        this.editDialog = false;
      });
    },
    // 分页切换
    handleSizeChange(size) {
      this.queryparms.limit = size;
      this.getFileList();
    },
    handleCurrentChange(page) {
      this.queryparms.page = page;
      this.getFileList();
    },
    // 左侧tab栏切换
    toggleClass(i, item) {
      this.cur = i;
      this.queryparms.cate_id = item;
      this.addFileForm.cate_id = item;
      this.addBatchFrom.cate_id = item;
      this.queryparms.page = 1;
      this.getFileList();
      this.addLetterForm.cate_id = item;
      this.getLetterCateWordList(item);
    },

    // 获取素材列表
    getFileList() {
      // getFile(this.queryparms).then(res => {
      //   this.total = res.data.total;
      //   this.FileList = res.data.data;
      // });
    },
    // 查询npm
    toQuery() {
      this.getFileList();
    }
  },

  created() {
    this.getMaterialList();
    this.getFileList();
  }
};
</script>

<style scoped lang="less">
* {
  box-sizing: border-box;
}
li {
  list-style: none;
  cursor: pointer;
}
/* video::-internal-media-controls-download-button {
    display:none;
}
video::-webkit-media-controls-enclosure {
    overflow:hidden;
}
video::-webkit-media-controls-panel {
    width: calc(100% + 30px); 
} */

.box {
  display: flex;
}
.left {
  /* flex: 2; */
  /* position: relative; */
  width: 200px;
}
::v-deep .el-card__body {
  padding: 0;
}
.left ul {
  font-size: 14px;
  height: 700px;
  color: #333333;
  margin-bottom: 36px;
}
.left ul li {
  padding-left: 20px;
  line-height: 50px;
  border-bottom: 1px solid #eeeeee;
}
.left ul .selected {
  background-color: #f5f7fa;
}
.right {
  flex: 10;
  margin-left: 20px;
}
.navs {
  margin-bottom: 20px;
  height: 120px;
  font-size: 14px;
  color: #333333;
  font-weight: 600;
  padding: 0 20px;
}
.navs .headers {
  /* display: flex; */
  border-bottom: 1px solid #eeeeee;
  line-height: 50px;
  width: 100%;
  height: 50px;
}
.navs .headers ul {
  width: 100%;
}
.navs .headers .selected {
  color: #38a28a;
  border-bottom: 1px solid #38a28a;
}
.navs .headers li {
  /* flex: 2; */
  height: 50px;
  float: left;
  margin-right: 60px;
}
.navs ::v-deep .el-form-item__label {
  color: #333333;
}
.add {
  width: 100%;
  /* position: absolute;
  bottom: 0;
  left: 0; */
}
.ck {
  float: right;
}
.ck i {
  margin-right: 10px;
}
.ck i:nth-child(1) {
  color: #3fbfa2;
}
.ck i:nth-child(2) {
  color: red;
}
.navs .search .inputs {
  width: 180px;
}
::v-deep .el-form-item__content {
  vertical-align: 0;
  /* height: 30px; */
}

.search {
  display: flex;
  justify-content: space-between;
  line-height: 70px;
}

.contents {
  padding: 20px;
  height: 772px;
  overflow: auto;
}
.material {
  position: relative;
  display: inline-block;
  width: 210px;
  height: 200px;
  /* background-color: skyblue; */
  margin: 0 20px 20px 0;
  text-align: center;
}
.material .tag {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  background-color: #999;
  opacity: 0.8;
  color: #f5f7fa;
}
.material img {
  width: 100%;
  height: 118px;
}
.material p {
  line-height: 30px;
  font-size: 14px;
  color: #333333;
  overflow: hidden;
}
.material .types {
  display: flex;
  line-height: 50px;
  justify-content: space-between;
}
.material .types .types-left img {
  width: 18px;
  height: 18px;
}
.material .types span {
  color: #969696;
  font-size: 12px;
  margin-left: 10px;
}
.pagination {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
}

.types-right {
  cursor: pointer;
}
.types .types-right i:nth-child(1) {
  margin-right: 10px;
  color: #85ce61;
}
.types .types-right i:nth-child(2) {
  margin-right: 10px;
}
.types .types-right i:nth-child(3) {
  color: red;
  margin-right: 10px;
}
.types .types-right i:nth-child(4) {
  color: dodgerblue;
}
.el-col {
  cursor: pointer;
}
.exhibition {
  width: 800px;
  height: 600px;
}
::v-deep .el-pager li.active {
  color: #ff6688;
}

::v-deep .el-dialog__body {
  padding: 20px;
}
::v-deep .el-upload--text {
  border: none;
  width: 100px;
  height: 100%;
  position: unset;
  overflow: unset;
}
.search_type_box {
  display: flex;
  align-items: center;
  padding-right: 10px;
  box-sizing: border-box;
}
.fs_01 {
  flex-shrink: 0;
}
.inline-input {
  width: 80%;
  margin-left: 5px;
}

.addtopic {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.add_box {
  margin-bottom: 20px;
  display: flex;
  .add_item {
    flex: 1;
    display: flex;
  }
  .add_btn {
    width: 100px;
  }
}
input[type="radio"] + label::before {
  content: " ";
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #999999;
  box-sizing: border-box;
  float: right;
  margin-top: 15px;
  margin-right: 30px;
}
input[type="radio"]:checked + label::before {
  background-color: #37a58c;
  background-clip: content-box;
  border: 1px solid #37a58c;
  padding: 0.2rem;
  box-sizing: border-box;
}
input[type="radio"] {
  opacity: 0;
  float: right;
  margin-top: 15px;
  margin-right: -15px;
}
</style>
